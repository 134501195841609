<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="寄存点">
        <SelectMerchant v-model="searchData.store_id"></SelectMerchant>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input style="width: 220px" clearable v-model.trim="searchData.order_sn" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="下单手机号">
        <el-input style="width: 220px" clearable v-model.trim="searchData.phone" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
          v-model="searchData.timeSlot"
          style="width: 220px"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          range-separator="-"
          size="mini"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.refund_status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.refund_status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.refund_status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.refund_status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>
    <el-button style="margin-bottom: 20px" icon="el-icon-download" type="primary" size="mini" plain @click="exportTable">导出</el-button>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="用户信息" min-width="180">
        <template slot-scope="{ row }">
          <div v-if="row.user" class="user-box">
            <div class="row">
              <span>ID：</span>
              <el-link type="primary" @click="goUser(row)">{{ row.user.id }}</el-link>
            </div>
            <div class="row">
              <span>昵称：</span>
              <span>{{ row.user.nickname }}</span>
            </div>
            <div class="row">
              <span>手机号：</span>
              <span>{{ row.user.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="store_title" label="商户" min-width="150">
        <template slot-scope="{ row }">
          {{ row.store.store_title }}
        </template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单编号" min-width="150"></el-table-column>
      <el-table-column prop="" label="审核状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.refund_status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="row.refund_status == 10" type="danger" size="mini">已拒绝</el-tag>
          <el-tag v-if="row.refund_status == 20" type="primary" size="mini">已通过</el-tag>

          <el-tag v-if="row.storage_status == 40" type="success" size="mini">{{ row.storage_status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="out_refund_no" label="退款编号" min-width="150"></el-table-column> -->
      <el-table-column prop="out_refund_money" label="退款金额" min-width="120"></el-table-column>
      <el-table-column prop="pay_money" label="订单金额" min-width="150">
        <template slot-scope="{ row }">
          <div>订单金额：{{ Number(row.pay_money) + Number(row.coupon_money) }}</div>
          <div>优惠金额：-{{ row.coupon_money }}</div>
          <div>支付金额：{{ row.pay_money }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="refund_reason" label="退款理由" min-width="120"></el-table-column>
      <el-table-column prop="refund_remark" label="审核备注" min-width="160"></el-table-column>

      <!-- <el-table-column prop="apply_refund_time" label="申请时间" min-width="160"></el-table-column> -->
      <el-table-column prop="updated_at" label="订单时间" min-width="220">
        <template slot-scope="{ row }">
          <div>申请时间：{{ row.apply_refund_time }}</div>
          <div>更新时间：{{ row.updated_at }}</div>
          <div>下单时间：{{ row.created_at }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="created_at" label="下单时间" min-width="170"></el-table-column> -->

      <!-- <el-table-column prop="storage_status_dsc" label="存储状态" min-width="100"></el-table-column> -->

      <!-- <el-table-column prop="store_money" label="商户收益" min-width="100"></el-table-column> -->
      <!-- <el-table-column prop="platform_money" label="平台收益" min-width="100"></el-table-column> -->
      <!-- <el-table-column prop="pay_method_dsc" label="支付方式" min-width="100"></el-table-column> -->
      <el-table-column prop="large_luggage_quantity" label="行李数量" min-width="120">
        <template slot-scope="{ row }">
          <div>大件：{{ row.large_luggage_quantity }}</div>
          <div>小件：{{ row.small_luggage_quantity }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="small_luggage_quantity" label="小件数量" min-width="100"></el-table-column> -->
      <el-table-column prop="" label="寄存时间" min-width="170">
        <template slot-scope="{ row }">
          <div class="row">
            <span>开始时间：</span>
            <span>{{ row.start_time }}</span>
          </div>
          <div class="row">
            <span>结束时间：</span>
            <span>{{ row.end_time }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
          <el-link v-if="row.refund_status == 0" style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="formData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="20">通过</el-radio>
            <el-radio :label="10">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="formData.status == 10" label="拒绝原因：">
          <el-input style="width: 300px" v-model="formData.refund_reject_reason" type="textarea"></el-input>
        </el-form-item>
        <el-form-item v-if="formData.status == 20" label="退款金额：">
          <el-input-number
            style="width: 300px"
            v-model="formData.out_refund_money"
            size="mini"
            :precision="2"
            :step="0.01"
            :min="0"
            :max="detail.pay_money"
            step-strictly
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注：" v-if="formData.status != 0">
          <el-input style="width: 300px" v-model="formData.refund_remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="show_review = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitReview">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <Detail ref="detail"></Detail>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { rejectAPI, passAPI, getListAPI, delAPI, DownloadAPI } from './api'
import SelectMerchant from '@/views/merchant/merchant-list/select-merchant-list.vue'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectMerchant, Detail },
  data() {
    return {
      show_review: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        refund_status: -1,
        store_id: '',
        order_sn: '',
        timeSlot: [],
        phone: ''
      },
      formData: {
        id: '',
        status: 20, //0 - 待审核 10 审核失败 20 - 审核通过
        refund_reject_reason: '',
        out_refund_money: '',
        refund_remark: '',
      },
      total: 0,
      detail: {}
    }
  },

  watch: {
    'searchData.refund_status': {
      immediate: true,
      handler(newV, oldV) {
        this.getList()
      }
    }
  },
  activated() {},
  mounted() {},

  methods: {
    goUser(row) {
      const url = this.$router.resolve({
        name: 'CustomerList',
        query: { id: row.user.id }
      }).href
      window.open(url, '_blank')
    },
    // 详情
    handleDetail(row) {
      this.$refs.detail.show = true
      this.$refs.detail.getDetail(row.id)
    },
    async getList() {
      const { page, page_size, refund_status, store_id, order_sn, phone, timeSlot } = this.searchData
      let params = { page, page_size, store_id, phone, order_sn }
      if (refund_status !== -1) {
        params.refund_status = refund_status
      }
      if (timeSlot && timeSlot.length) {
        params.created_at_start = timeSlot[0]
        params.created_at_end = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data
      this.total = res.total
    },
    handleClickTab(i) {
      if (this.searchData.refund_status == i) return
      this.searchData.refund_status = i
    },
    handleReview(row) {
      this.detail = row
      this.detail.pay_money = Number(row.pay_money)
      this.formData = {
        id: row.id,
        status: 20, //0 - 待审核 10 审核失败 20 - 审核通过
        refund_reject_reason: '',
        out_refund_money: row.out_refund_money
      }
      this.show_review = true
    },
    submitReview() {
      const { id, status, refund_reject_reason, out_refund_money,refund_remark } = this.formData
      if (status == 10) {
        const data = { id, refund_reject_reason, refund_remark }
        rejectAPI(data).then(() => {
          this.$message.success('操作成功')
          this.show_review = false
          this.getList()
        })
      }
      if (status == 20) {
        const data = { id, out_refund_money, refund_remark }
        passAPI(data).then(() => {
          this.$message.success('操作成功')
          this.show_review = false
          this.getList()
        })
      }
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleDel({ id }) {
      this.$confirm('确认删除该订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    exportTable() {
      const { refund_status, store_id, order_sn, phone, timeSlot } = this.searchData
      let params = { store_id, order_sn, phone }
      if (refund_status !== -1) {
        params.refund_status = refund_status
      }
      if (timeSlot && timeSlot.length) {
        params.created_at_start = timeSlot[0]
        params.created_at_end = timeSlot[1]
      }
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;
      transition: all 0.2s linear;
      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .user-box {
    .row {
      display: flex;
      .label {
        min-width: 100px;
        text-align: right;
      }
      .value {
        text-align: left;
        min-width: 150px;
      }
    }
  }
  .withdraw-box {
    .row {
      display: flex;
      .label {
        min-width: 100px;
        text-align: right;
      }
      .value {
        text-align: left;
        min-width: 100px;
      }
    }
  }
}
</style>
