<template>
  <el-drawer class="wrapper" title="退款详情" :visible.sync="show" direction="rtl" size="50%">
    <div class="content">
      <admin-title title="订单信息"></admin-title>
      <el-descriptions style="margin-bottom: 20px" direction="vertical" :column="4" border>
        <el-descriptions-item label="订单编号">{{ detail.order_sn }}</el-descriptions-item>
        <el-descriptions-item label="退款编号">{{ detail.out_refund_no }}</el-descriptions-item>
        <el-descriptions-item label="支付金额">{{ detail.pay_money }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ detail.storage_status_dsc }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{ detail.pay_method_dsc }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ detail.created_at }}</el-descriptions-item>
        <el-descriptions-item label="寄存时间" :span="3">{{ detail.start_time }} ~ {{ detail.end_time }}</el-descriptions-item>

        <el-descriptions-item label="大件数量">{{ detail.large_luggage_quantity }}</el-descriptions-item>
        <el-descriptions-item label="小件数量">{{ detail.small_luggage_quantity }}</el-descriptions-item>

        <el-descriptions-item label="商户收益">{{ detail.store_money }}</el-descriptions-item>
        <el-descriptions-item label="平台收益">{{ detail.platform_money }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ detail.refund_remark }}</el-descriptions-item>
        <el-descriptions-item v-if="detail.refund_reject_reason" label="拒绝原因">{{ detail.refund_reject_reason }}</el-descriptions-item>
      </el-descriptions>
      <admin-title title="寄存点信息"> </admin-title>
      <el-descriptions v-if="detail.store">
        <el-descriptions-item label="名称">{{ detail.store.store_title }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{ detail.store.contact }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{ detail.store.address }}</el-descriptions-item>
        <el-descriptions-item label="标签">
          <el-tag style="margin-right: 5px" type="primary" size="mini" v-for="(item, i) in detail.store.tags" :key="i">{{ item }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="评价">
          <el-rate :max="5" disabled allow-half :value="detail.store.star_num / 2"></el-rate>
        </el-descriptions-item>
        <el-descriptions-item label="工作日">{{ detail.store.work_day }}</el-descriptions-item>

        <el-descriptions-item label="照片">
          <el-image
            class="cover"
            style="width: 80px; height: 80px; border-radius: 4px; display: block"
            :src="detail.store.img"
            :preview-src-list="[detail.store.img]"
            fit="cover"
          >
            <div slot="error" style="line-height: 80px; text-align: center; background: #f5f7fa">无</div>
          </el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
import { getDetailAPI } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      show: false,
      detail: {}
    }
  },

  mounted() {},

  methods: {
    async getDetail(id) {
      this.detail = await getDetailAPI(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-drawer__header {
    font-weight: 600;
    font-size: 18px;
  }
  .content {
    padding: 20px;
  }
}
</style>
